import { gameManager } from "../Game/GameManager";
import { setEmailUser } from "../../Stores/CreateMapStore";
import { LoginSceneName } from "../Login/LoginScene";
import { Scene } from "phaser";
import { EntrySceneName } from "../Login/EntryScene";
import { localUserStore } from "../../Connexion/LocalUserStore";

export const SetEmailUserScene = "SetEmailUserScene";
export class SetEmailUser extends Scene {
    constructor() {
        super({
            key: SetEmailUserScene,
        });
    }

    preload() {}

    create() {
        setEmailUser.set(true);
    }

    public async Continue(email: string, password: string, name: string, organization: string, login: boolean) {
        try {
            let response;
            if (login) {
                response = await gameManager.login(email, password);
            } else {
                response = await gameManager.createUser(email, password, name, organization);
            }
            if (response) {
                const data = response.data;
                localUserStore.setAuthToken(data.authToken);
                gameManager.setEmailUser(data.email);
                gameManager.setPlayerName(data.name);
                gameManager.setOrganization(data.organization);
                if (data.goToList) {
                    window.location.href = `/@/${data.organization}`;
                } else if (login && data.userMap) {
                    window.location.href = `/@/${data.organization}/${data.userMap}`;
                } else {
                    window.location.href = `/createMap`;
                }
            }
            setEmailUser.set(false);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
    update(time: number, delta: number): void {}
    public onResize(): void {}
}
