import { writable } from "svelte/store";
import { playersStore } from "./PlayersStore";
import type { PlayerInterface } from "../Phaser/Game/PlayerInterface";
import { Subject } from "rxjs";
import Axios from "axios";
import { PUSHER_URL } from "../Enum/EnvironmentVariable";
import { Character } from "../Phaser/Entity/Character";

export const chatVisibilityStore = writable(false);
export const chatInputFocusStore = writable(false);

export const newChatMessageStore = writable<string | null>(null);

export enum ChatMessageTypes {
    text = 1,
    me,
    userIncoming,
    userOutcoming,
}

export interface ChatMessage {
    type: ChatMessageTypes;
    date: Date;
    author?: PlayerInterface;
    targets?: PlayerInterface[];
    text?: string[];
}

function getAuthor(authorId: number): PlayerInterface {
    const author = playersStore.getPlayerById(authorId);
    if (!author) {
        throw "Could not find data for author " + authorId;
    }
    return author;
}

function createChatMessagesStore() {
    const { subscribe, update } = writable<ChatMessage[]>([]);

    return {
        subscribe,
        addIncomingUser(authorId: number) {
            update((list) => {
                const lastMessage = list[list.length - 1];
                if (lastMessage && lastMessage.type === ChatMessageTypes.userIncoming && lastMessage.targets) {
                    lastMessage.targets.push(getAuthor(authorId));
                } else {
                    list.push({
                        type: ChatMessageTypes.userIncoming,
                        targets: [getAuthor(authorId)],
                        date: new Date(),
                    });
                }
                return list;
            });
        },
        addOutcomingUser(authorId: number) {
            update((list) => {
                const lastMessage = list[list.length - 1];
                if (lastMessage && lastMessage.type === ChatMessageTypes.userOutcoming && lastMessage.targets) {
                    lastMessage.targets.push(getAuthor(authorId));
                } else {
                    list.push({
                        type: ChatMessageTypes.userOutcoming,
                        targets: [getAuthor(authorId)],
                        date: new Date(),
                    });
                }
                return list;
            });
        },
        addPersonnalMessage(text: string) {
            newChatMessageStore.set(text);
            update((list) => {
                const lastMessage = list[list.length - 1];
                if (lastMessage && lastMessage.type === ChatMessageTypes.me && lastMessage.text) {
                    lastMessage.text.push(text);
                } else {
                    list.push({
                        type: ChatMessageTypes.me,
                        text: [text],
                        date: new Date(),
                    });
                }
                return list;
            });
        },
        addExternalMessage(authorId: number, text: string) {
            update((list) => {
                const lastMessage = list[list.length - 1];
                if (lastMessage && lastMessage.type === ChatMessageTypes.text && lastMessage.text) {
                    lastMessage.text.push(text);
                } else {
                    list.push({
                        type: ChatMessageTypes.text,
                        text: [text],
                        author: getAuthor(authorId),
                        date: new Date(),
                    });
                }
                return list;
            });
            chatVisibilityStore.set(true);
        },
    };
}

//Chat

function createChatStore() {
    const chatStream = new Subject<any>(); /* eslint-disable-line */
    return {
        getStream: function () {
            return chatStream;
        },
    };
}
export const chatStore = createChatStore();

function createChatMessagesStream() {
    const chatMessageStream = new Subject<any>(); /* eslint-disable-line */
    return {
        getStream: function () {
            return chatMessageStream;
        },
    };
}
export const chatMessagesStream = createChatMessagesStream();

function createChatCurrentUserInfoStream() {
    const chatCurrentUserInfoStream = new Subject<any>(); /* eslint-disable-line */
    return {
        getStream: function () {
            return chatCurrentUserInfoStream;
        },
    };
}
export const chatCurrentUserInfoStream = createChatCurrentUserInfoStream();

//Chat

export const chatMessagesStore = createChatMessagesStore();

export const getPreviousMessagesByUuid = async (senderUuid: string, receiverUuid: string, type: string) => {
    return await Axios.post(`${PUSHER_URL}/previous_messages?type=${type}`, {
        senderUuid,
        receiverUuid,
        type,
    }).then((data) => {
        return data.data;
    });
};

export const uploadChatFile = async (formData: FormData, groupId: string) => {
    return await Axios.post(`${PUSHER_URL}/upload_chat_file`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }).then((data) => {
        return data.data;
    });
};

export const getGroupsByUuid = async (senderUuid: string, receiverUuid: string, type: string) => {
    return await Axios.get(`${PUSHER_URL}/groups`).then((data) => {
        return data.data;
    });
};

export const createGroup = async (
    token: string,
    data: {
        identifiers: [string] | [];
        name: string;
        // avatar: String;
        // roomId: String;
        organization: string;
    }
) => {
    return await Axios.post(`${PUSHER_URL}/create_group`, data, { headers: { "auth-token": token } }).then((data) => {
        return data.data;
    });
};
/* eslint-disable-next-line */
export const createChannel = async (token: string, receiver: string) => {
    return await Axios.post(
        `${PUSHER_URL}/create_channel`,
        { uuid: receiver },
        { headers: { "auth-token": token } }
    ).then((data) => {
        return data.data;
    });
};

export const createFileDownloadLink = async (
    token: string,
    data: {
        uuid: string;
        name: string;
        type: string;
    }
) => {
    return await Axios.post(`${PUSHER_URL}/create_file_download_link`, data, { headers: { "auth-token": token } }).then(
        (data) => {
            return data.data;
        }
    );
};

function createChatSubMenuVisibilityStore() {
    const { subscribe, update } = writable<string>("");

    return {
        subscribe,
        openSubMenu(playerName: string, index: number) {
            const id = playerName + index;
            update((oldValue) => {
                return oldValue === id ? "" : id;
            });
        },
    };
}

export const chatSubMenuVisbilityStore = createChatSubMenuVisibilityStore();
