import { MAX_USERNAME_LENGTH } from "../Enum/EnvironmentVariable";

export interface CharacterTexture {
    id: number;
    level: number;
    url: string;
    rights: string;
}

export const maxUserNameLength: number = MAX_USERNAME_LENGTH;

export function isUserNameValid(value: unknown): boolean {
    return typeof value === "string" && value.length > 0 && value.length <= maxUserNameLength && /\S/.test(value);
}
export function validateEmail(email: unknown): boolean {
    return (
        typeof email === "string" &&
        email.length > 0 &&
        !!email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
}
export function validateOrganization(value: unknown): boolean {
    return typeof value === "string" && value.length > 0;
}

export function areCharacterLayersValid(value: string[] | null): boolean {
    if (!value || !value.length) return false;
    for (let i = 0; i < value.length; i++) {
        if (/^\w+$/.exec(value[i]) === null) {
            return false;
        }
    }
    return true;
}

export class LocalUser {
    constructor(public readonly uuid: string, public textures: CharacterTexture[]) {}
}
