import { gameManager } from "../Game/GameManager";
import { listMaps, setEmailUser } from "../../Stores/CreateMapStore";
import { Scene } from "phaser";
import Axios from "axios";
import { PUSHER_URL } from "../../Enum/EnvironmentVariable";
import { localUserStore } from "../../Connexion/LocalUserStore";

export const ListMapsName = "ListMapsName";
export class ListMaps extends Scene {
    public list:
        | []
        | [
              {
                  id: number;
                  mapOrganization: string;
                  mapName: string;
                  mapImage: string;
                  mapSize: string;
                  requested: boolean;
                  mapOwner: boolean;
              }
          ] = [];
    constructor() {
        super({
            key: ListMapsName,
        });
    }

    preload() {}

    async create() {
        try {
            const response = await gameManager.getOrgMaps();
            if (response) {
                console.log(this.list);
                // @ts-ignore
                this.list = [...response];
                console.log(this.list);
            }
        } catch (reason) {
            this.list = [];
        }
        listMaps.set(true);
    }

    public async Continue(data: {
        mapOrganization: string;
        mapName: string;
        mapImage: string;
        mapSize: string;
        requested: boolean;
    }): Promise<void> {
        try {
            const response = await Axios.post(
                `${PUSHER_URL}/requestAccessMap`,
                { mapOrganization: data.mapOrganization, mapName: data.mapName },
                { headers: { "auth-token": localUserStore.getAuthToken() } }
            );
            if (response) {
                const data = response.data;
                console.log(data);
                alert("Success sending request!");
                window.location.reload();
            }
        } catch (e) {
            console.log(e);
        }
        /*gameManager.setSelectedMapType(this.type);
        selectedMapType.set(false);
        */
    }

    update(time: number, delta: number): void {}
    public onResize(): void {}
}
