<script>
    import RoomPassword from "./RoomPassword.svelte";
    import ListOwner from "./ListOwner.svelte";
    import {modal, modalListOwner, modalRoomPassword} from "../../../Stores/ModalStore";
    function closeModal() {
        modalListOwner.set(false);
        modalRoomPassword.set(false);
        modal.set(false);
    }
</script>
<div style="pointer-events: auto">
    <div class="backgroundModal" on:click|preventDefault={closeModal}></div>
    {#if $modalRoomPassword}
        <RoomPassword {closeModal}/>
    {/if}
    {#if $modalListOwner}
        <ListOwner {closeModal}/>
    {/if}
</div>