<script lang="typescript">
    import type {Game} from "../../Phaser/Game/Game";
    import {SelectMapType, SelectMapTypeName} from "../../Phaser/CreateMap/SelectMapType";
    import {GameConnexionTypes, urlManager} from "../../Url/UrlManager";

    export let game: Game;
    let type: string = "";
    const templates = [
        {
            mapUrl: "map.captown.io/first_room/map.json",
            mapImage: "/static/images/maps/default/first_room.png",
            mapName: "first_room",
            mapSize: "25-50"
        },
        {
            mapUrl: "map.captown.io/Floor0/floor0.json",
            mapImage: "/static/images/maps/default/floor0.png",
            mapName: "Floor0",
            mapSize: "25-50"
        },
        {
            mapUrl: "map.captown.io/Floor1/floor1.json",
            mapImage: "/static/images/maps/default/floor1.png",
            mapName: "Floor1",
            mapSize: "25-50"
        },
        {
            mapUrl: "map.captown.io/Floor2/floor2.json",
            mapImage: "/static/images/maps/default/floor2.png",
            mapName: "Floor2",
            mapSize: "25-50"
        },
        {
            mapUrl: "map.captown.io/Lyon/lyon.json",
            mapImage: "/static/images/maps/default/lyon.png",
            mapName: "Lyon",
            mapSize: "25-50"
        },
        {
            mapUrl: "map.captown.io/Tuto/tutoV3.json",
            mapImage: "/static/images/maps/default/tuto.png",
            mapName: "Tuto",
            mapSize: "25-50"
        },
        {
            mapUrl: "map.captown.io/Village/Village.json",
            mapImage: "/static/images/maps/default/village.png",
            mapName: "Village",
            mapSize: "25-50"
        },
    ];
    const selectMapType = game.scene.getScene(SelectMapTypeName) as SelectMapType;

    function selectType(t: string) {
        selectMapType.setType(t);
        type = t;
    }

    function Continue() {
        if (type !== '')
            selectMapType.Continue();
        else {
            alert("Selecione um mapa!");
        }
    }


</script>

<form class="selectMapSize">
    <section class="text-center">
        <h2>Selecione o tipo do mapa</h2>
        {#if urlManager.getGameConnexionType()===GameConnexionTypes.group}
            <span style="color: red">(Ao completar o cadastro do mapa todos os outros mapas serão removidos)</span>
        {/if}
        <div class="templatesMapType">
            {#each templates as template}
                <button class="selectMapSizeButton nes-btn { type === template.mapUrl?'active':''}"
                        on:click|preventDefault={ _=>selectType(template.mapUrl) }>
                    <img src="{template.mapImage}" alt="{template.mapName}"><br>
                    {template.mapName}<br>
                    {template.mapSize}
                </button>
            {/each}
        </div>
    </section>
    <section class="action">
        <button type="submit" class="selectMapSizeFormSubmit nes-btn is-primary" on:click|preventDefault={ Continue }>
            Continuar
        </button>
    </section>
</form>

<style lang="scss">
    form.selectMapSize {
        font-family: "Press Start 2P";
        pointer-events: auto;
        color: #ebeeee;

        section {
            margin: 10px;

            &.action {
                text-align: center;
            }

            h2 {
                font-family: "Press Start 2P";
                margin: 1px;
            }

            &.text-center {
                text-align: center;
            }
            .templatesMapType{
                display: flex;
                flex-wrap: wrap;
                max-width: 800px;
                margin: 0 auto;
                justify-content: space-around;
            }
            .selectMapSizeButton{
                width: 250px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img{
                    width:190px;
                    height:90px;
                }
            }
        }

        button {
            font-family: "Press Start 2P";
            &.active {
                background: #212529;
                color: white;
            }
        }
    }
</style>
