<script lang="typescript">
    import type {Game} from "../../Phaser/Game/Game";
    import {ListMaps, ListMapsName} from "../../Phaser/ListMaps/ListMaps";
    import {userIsOrgOwnerStore} from "../../Stores/GameStore";

    export let game: Game;
    let map: null|{
        id:number,
        mapOrganization: string,
        mapName: string,
        mapImage: string,
        mapSize: string,
        mapOwner: boolean,
        requested:boolean
    };
    const listMaps = game.scene.getScene(ListMapsName) as ListMaps;
    let sending = false
    function Continue() {
        if (map) {
            if (!sending) {
                sending = true;
                listMaps.Continue(map);
                sending = false;
            }
        }else {
            alert("Select a map!");
        }
    }


</script>

<form class="listMaps">
    <section class="text-center">
        <h2>Organization List Map</h2>
        <div class="templatesMapType">
            {#each listMaps.list as template}
                <button class="listMapsButton nes-btn { (map && map.id === template.id)?'active':''}"
                        on:click|preventDefault={ _=>map=template }>
                    <img src="{template.mapImage}" alt="{template.mapName}"><br>
                    {template.mapName}<br>
                    {#if template.requested}Requested{/if}<br>
                    {template.mapSize}
                </button>
            {/each}
        </div>
    </section>
    <section class="action">
        {#if map != null && !map.requested && !$userIsOrgOwnerStore}
            <button type="submit" class="listMapsFormSubmit nes-btn is-primary" on:click|preventDefault={ Continue }>
                Request Access
            </button>
        {/if}
        {#if map != null && $userIsOrgOwnerStore}
            <button type="submit" class="listMapsFormSubmit nes-btn is-primary" on:click|preventDefault={ ()=> window.location.href = (`/@/${map.mapOrganization}/${map.mapName}`) }>
                Join Map
            </button>
        {/if}
        {#if listMaps.list.length>0 && !listMaps.list[listMaps.list.length-1].mapOwner }
            <button type="submit" class="listMapsFormSubmit nes-btn is-primary" on:click|preventDefault={ _=>window.location.href = ("/createMap") }>
                CreateMap
            </button>
            <br><a on:click|preventDefault={()=>{
                const mail = `mailto:mhheisler@gmail.com?subject=Pedido%20de%20reivindicação%20de%20organização&body=Olá%20quero%20essa%20${listMaps.list[listMaps.list.length-1].mapOrganization}`;
                window.open(mail,"_blank");
            }}>Pedido de reivindicação de organização.(Anexar Contrato social)</a>
        {/if}
    </section>
</form>

<style lang="scss">
    form.listMaps {
        font-family: "Press Start 2P";
        pointer-events: auto;
        color: #ebeeee;

        section {
            margin: 10px;

            &.action {
                text-align: center;
            }

            h2 {
                font-family: "Press Start 2P";
                margin: 1px;
            }

            &.text-center {
                text-align: center;
            }
            .templatesMapType{
                display: flex;
                flex-wrap: wrap;
                max-width: 800px;
                margin: 0 auto;
                justify-content: space-around;
            }
            .listMapsButton{
                width: 250px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img{
                    width:190px;
                    height:90px;
                }
            }
        }

        button {
            font-family: "Press Start 2P";
            &.active {
                background: #212529;
                color: white;
            }
        }
    }
</style>
