import { gameManager } from "../Game/GameManager";
import { selectedMapType } from "../../Stores/CreateMapStore";
import { Scene } from "phaser";
import { SelectMapNameScene } from "./SelectMapName";

export const SelectMapTypeName = "SelectMapTypeScene";
export class SelectMapType extends Scene {
    private type: string;

    constructor() {
        super({
            key: SelectMapTypeName,
        });
        this.type = gameManager.getSelectedMapType() || "";
    }

    preload() {}

    create() {
        selectedMapType.set(true);
    }

    public Continue(): void {
        gameManager.setSelectedMapType(this.type);
        selectedMapType.set(false);
        this.scene.stop(SelectMapTypeName);
        gameManager.tryResumingGame(this, SelectMapNameScene);
        this.scene.remove(SelectMapTypeName);
    }

    update(time: number, delta: number): void {}
    public setType(type: string) {
        this.type = type;
    }
    public getType(): string {
        return this.type;
    }
    public onResize(): void {}
}
