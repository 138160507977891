import { GameScene } from "./GameScene";
import { connectionManager } from "../../Connexion/ConnectionManager";
import { Room } from "../../Connexion/Room";
import { MenuScene, MenuSceneName } from "../Menu/MenuScene";
import { LoginSceneName } from "../Login/LoginScene";
import { SelectCharacterSceneName } from "../Login/SelectCharacterScene";
import { EnableCameraSceneName } from "../Login/EnableCameraScene";
import { localUserStore } from "../../Connexion/LocalUserStore";
import { get } from "svelte/store";
import { requestedCameraState, requestedMicrophoneState } from "../../Stores/MediaStore";
import { helpCameraSettingsVisibleStore } from "../../Stores/HelpCameraSettingsStore";
import { SelectMapTypeName } from "../CreateMap/SelectMapType";
import { GameConnexionTypes, urlManager } from "../../Url/UrlManager";
import { SetEmailUserScene } from "../CreateMap/SetEmailUser";
import Axios from "axios";
import { PUSHER_URL } from "../../Enum/EnvironmentVariable";
import { ListMapsName } from "../ListMaps/ListMaps";
import { userIsOrgOwnerStore } from "../../Stores/GameStore";

/**
 * This class should be responsible for any scene starting/stopping
 */
export class GameManager {
    private playerName: string | null;
    private organization: string | null;
    private characterLayers: string[] | null;
    private companion: string | null;
    private startRoom!: Room;
    private selectedMapType!: string | null;
    private selectedMapName!: string | null;
    private emailUser!: string | null;
    roomPasswordName: string = "";
    openDoor: (roomPasswordName: string) => void = () => {};
    currentGameSceneName: string | null = null;

    constructor() {
        this.selectedMapType = localUserStore.getSelectedMapType();
        this.selectedMapName = localUserStore.getSelectedMapName();
        this.emailUser = localUserStore.getEmailUser();
        this.playerName = localUserStore.getName();
        this.organization = localUserStore.getOrganization();
        this.characterLayers = localUserStore.getCharacterLayers();
        this.companion = localUserStore.getCompanion();
    }
    public getUserDetails() {
        return Axios.post(
            `${PUSHER_URL}/userDetails`,
            {},
            {
                headers: { "auth-token": localUserStore.getAuthToken() },
            }
        );
    }
    public async init(scenePlugin: Phaser.Scenes.ScenePlugin): Promise<string> {
        const connectionType = urlManager.getGameConnexionType();
        if (connectionType === GameConnexionTypes.organizationRequest) {
            const url = window.location.pathname.toString();
            const afterUrl = url.split("organizationReq/").pop()!.split("-");
            const email = afterUrl[0];
            const org = afterUrl[1];

            const result = await Axios.post(`${PUSHER_URL}/changeOrg`, {
                email: email,
                organization: org,
            });
            window.location.href = "/";
        }
        if (connectionType !== GameConnexionTypes.invited) {
            if (!localUserStore.getAuthToken()) {
                if (connectionType === GameConnexionTypes.empty) return SetEmailUserScene;
                else window.location.href = "/";
            }
            try {
                if (connectionType === GameConnexionTypes.acceptRefuse) {
                    const routeName = window.location.pathname.toString().split("@/").pop();
                    if (routeName?.includes("accept")) {
                        const request_id = routeName.split("accept/").pop();
                        await this.acceptMap(request_id ?? "");
                        alert("Accept success");
                        window.location.href = "/";
                    }
                    if (routeName?.includes("refuse")) {
                        const request_id = routeName.split("refuse/").pop();
                        await this.refuseMap(request_id ?? "");
                        alert("Refuse success");
                        window.location.href = "/";
                    }
                }
                const response = await this.getUserDetails();
                const dataUserDetails = response.data;
                this.setPlayerName(dataUserDetails.name);
                this.setOrganization(dataUserDetails.organization);
                userIsOrgOwnerStore.set(dataUserDetails.isMapOwner);
                if (dataUserDetails.isMapOwner) {
                    if (connectionType === GameConnexionTypes.organizationList) return ListMapsName;
                    if (connectionType === GameConnexionTypes.group) return SelectMapTypeName;
                    if (dataUserDetails.userMap !== "") {
                        if (connectionType !== GameConnexionTypes.organization) {
                            window.location.href = `/@/${dataUserDetails.organization}/${dataUserDetails.userMap}`;
                        }
                    } else {
                        window.location.href = `/group/${localUserStore.getOrganization()}`;
                    }
                } else {
                    if (dataUserDetails.userMap !== "") {
                        if (connectionType !== GameConnexionTypes.organization) {
                            window.location.href = `/@/${dataUserDetails.organization}/${dataUserDetails.userMap}`;
                        }
                    } else if (dataUserDetails.goToList && connectionType !== GameConnexionTypes.createMap) {
                        console.log("dataUserDetails.goToList && connectionType !== GameConnexionTypes.createMap");
                        if (connectionType !== GameConnexionTypes.organizationList) {
                            console.log("connectionType !== GameConnexionTypes.organizationList");
                            window.location.href = `/@/${dataUserDetails.organization}`;
                        } else return ListMapsName;
                    } else {
                        if (connectionType !== GameConnexionTypes.createMap) window.location.href = `/createMap`;
                        else return SelectMapTypeName;
                    }
                }
            } catch (e) {
                if (e.response.data.reason == "tokenInvalid") {
                    connectionManager.logout();
                }
                throw e;
            }
        }
        this.startRoom = await connectionManager.initGameConnexion();
        this.loadMap(this.startRoom, scenePlugin);

        if (!this.playerName) {
            return LoginSceneName;
        } else if (!this.characterLayers || !this.characterLayers.length) {
            return SelectCharacterSceneName;
        } else {
            return EnableCameraSceneName;
        }
    }

    public setPlayerName(name: string): void {
        this.playerName = name;
        localUserStore.setName(name);
    }
    getPlayerName(): string | null {
        return this.playerName;
    }
    public setOrganization(name: string): void {
        this.organization = name;
        localUserStore.setOrganization(name);
    }
    getOrganization(): string | null {
        return this.organization;
    }
    public setSelectedMapType(type: string): void {
        this.selectedMapType = type;
        localUserStore.setSelectedMapType(type);
    }
    public getSelectedMapType(): string | null {
        return localUserStore.getSelectedMapType();
    }
    public setSelectedMapName(name: string): void {
        this.selectedMapName = name;
        localUserStore.setSelectedMapName(name);
    }

    public getSelectedMapName(): string | null {
        return localUserStore.getSelectedMapName();
    }

    public setEmailUser(email: string): void {
        this.emailUser = email;
        localUserStore.setEmailUser(email);
    }
    public getEmailUser(): string {
        return localUserStore.getEmailUser();
    }
    public setCharacterLayers(layers: string[]): void {
        this.characterLayers = layers;
        localUserStore.setCharacterLayers(layers);
    }

    getCharacterLayers(): string[] {
        if (!this.characterLayers) {
            throw "characterLayers are not set";
        }
        return this.characterLayers;
    }

    setCompanion(companion: string | null): void {
        this.companion = companion;
    }

    getCompanion(): string | null {
        return this.companion;
    }

    public loadMap(room: Room, scenePlugin: Phaser.Scenes.ScenePlugin) {
        const roomID = room.key;

        const gameIndex = scenePlugin.getIndex(roomID);
        if (gameIndex === -1) {
            const game: Phaser.Scene = new GameScene(room, room.mapUrl);
            scenePlugin.add(roomID, game, false);
        }
    }

    public goToStartingMap(scenePlugin: Phaser.Scenes.ScenePlugin): void {
        console.log("starting " + (this.currentGameSceneName || this.startRoom.key));
        scenePlugin.start(this.currentGameSceneName || this.startRoom.key);
        scenePlugin.launch(MenuSceneName);

        if (
            !localUserStore.getHelpCameraSettingsShown() &&
            (!get(requestedMicrophoneState) || !get(requestedCameraState))
        ) {
            helpCameraSettingsVisibleStore.set(true);
            localUserStore.setHelpCameraSettingsShown();
        }
    }

    public gameSceneIsCreated(scene: GameScene) {
        this.currentGameSceneName = scene.scene.key;
        const menuScene: MenuScene = scene.scene.get(MenuSceneName) as MenuScene;
        menuScene.revealMenuIcon();
    }

    /**
     * Temporary leave a gameScene to go back to the loginScene for example.
     * This will close the socket connections and stop the gameScene, but won't remove it.
     */
    leaveGame(scene: Phaser.Scene, targetSceneName: string, sceneClass: Phaser.Scene): void {
        if (this.currentGameSceneName === null) throw "No current scene id set!";
        const gameScene: GameScene = scene.scene.get(this.currentGameSceneName) as GameScene;
        gameScene.cleanupClosingScene();
        scene.scene.stop(this.currentGameSceneName);
        scene.scene.sleep(MenuSceneName);
        if (!scene.scene.get(targetSceneName)) {
            scene.scene.add(targetSceneName, sceneClass, false);
        }
        scene.scene.run(targetSceneName);
    }

    /**
     * follow up to leaveGame()
     */
    tryResumingGame(scene: Phaser.Scene, fallbackSceneName: string) {
        if (this.currentGameSceneName) {
            scene.scene.start(this.currentGameSceneName);
            scene.scene.wake(MenuSceneName);
        } else {
            scene.scene.run(fallbackSceneName);
        }
    }

    public getCurrentGameScene(scene: Phaser.Scene): GameScene {
        if (this.currentGameSceneName === null) throw "No current scene id set!";
        return scene.scene.get(this.currentGameSceneName) as GameScene;
    }

    CreateMap() {
        const roomType = this.getSelectedMapType();
        const roomName = this.getSelectedMapName();
        const organization = this.getOrganization();
        const token = localUserStore.getAuthToken();
        if (roomType && roomName && organization && token) {
            return Room.createMap(token, {
                roomType,
                roomName,
                organization,
            });
        }
        throw "Error";
    }

    createUser(email: string, password: string, name: string, organization: string) {
        return Axios.post(
            `${PUSHER_URL}/createUser`,
            { email, password, name, organization },
            { headers: { "auth-token": localUserStore.getAuthToken() } }
        );
    }
    login(email: string, password: string) {
        return Axios.post(
            `${PUSHER_URL}/loginUser`,
            { email, password },
            { headers: { "auth-token": localUserStore.getAuthToken() } }
        );
    }
    setPasswordDoor(Name: string, Password: string) {
        const sendData = {
            name: Name,
            password: Password,
        };
        return Axios.post(`${PUSHER_URL}/passwordDoor/send`, sendData, {
            headers: { "auth-token": localUserStore.getAuthToken() },
        });
    }

    sendPassworDoor(Name: string, Password: string) {
        const sendData = {
            name: Name,
            password: Password,
        };
        return Axios.post(`${PUSHER_URL}/passwordDoor/check`, sendData, {
            headers: { "auth-token": localUserStore.getAuthToken() },
        });
    }

    private acceptMap(request_id: string) {
        const sendData = {
            request_id: request_id,
        };
        return Axios.post(`${PUSHER_URL}/accept/map`, sendData, {
            headers: { "auth-token": localUserStore.getAuthToken() },
        });
    }
    private refuseMap(request_id: string) {
        const sendData = {
            request_id: request_id,
        };
        return Axios.post(`${PUSHER_URL}/refuse/map`, sendData, {
            headers: { "auth-token": localUserStore.getAuthToken() },
        });
    }

    async getOrganizations() {
        const organizations = await Axios.post(
            `${PUSHER_URL}/admin/organizations`,
            {},
            {
                headers: { "auth-token": localUserStore.getAuthToken() },
            }
        );
        return organizations.data;
    }

    async getUsers(organizationSelected: String) {
        const users = await Axios.post(
            `${PUSHER_URL}/admin/users/organization`,
            { organization: organizationSelected },
            {
                headers: { "auth-token": localUserStore.getAuthToken() },
            }
        );
        return users.data;
    }

    async setOwner(organizationSelected: String, userSelected: String) {
        const users = await Axios.post(
            `${PUSHER_URL}/admin/setOwner/organization`,
            { organization: organizationSelected, user: userSelected },
            {
                headers: { "auth-token": localUserStore.getAuthToken() },
            }
        );
        return users.data;
    }

    async getOrgMaps() {
        const response = await Axios.get(`${PUSHER_URL}/organization/maps`, {
            headers: { "auth-token": localUserStore.getAuthToken() },
        });
        return response.data;
    }
    async GroupMaps() {
        const roomType = this.getSelectedMapType();
        const roomName = this.getSelectedMapName();
        const organization = this.getOrganization();
        const token = localUserStore.getAuthToken();
        if (roomType && roomName && organization && token) {
            const result = await Axios.post(
                `${PUSHER_URL}/mapCreate/groupMaps`,
                { roomType, roomName, organization },
                { headers: { "auth-token": token } }
            );
            if (result.status === 200) {
                const data = result.data;
                if (data.name && data.organization && data.world) {
                    return {
                        name: data.name as string,
                        organization: data.organization as string,
                        world: data.world as string,
                    };
                }
            }
        }
        throw "Error";
    }
}

export const gameManager = new GameManager();
