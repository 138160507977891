<script lang="typescript">
    import { fly } from 'svelte/transition';
    import {userIsMapAdminStore} from "../../../Stores/GameStore";
    import {modal, modalRoomPassword} from "../../../Stores/ModalStore";
    import {gameManager} from "../../../Phaser/Game/GameManager";
    let openAdmin = false;
    let sending = false;
    let success = false;
    let errorPassword = false;
    let password = '';
    export let closeModal: ()=>{};

    const submit= async ()=>{
        sending = true;
        errorPassword = false;
        if (password==''){
            errorPassword = true;
            sending = false;
            return;
        }
        try {
            let response;
            if (openAdmin) {
                response = await gameManager.setPasswordDoor(gameManager.roomPasswordName, password);
                if (response.status == 200){
                    success = true;
                    sending = false;
                    alert("Password change success!");
                    return;
                }
            }
            else {
                response = await gameManager.sendPassworDoor(gameManager.roomPasswordName, password);
                if (response.status == 200){
                    if (response.data.canEnter) {
                        success = true;
                        sending = false;
                        gameManager.openDoor(gameManager.roomPasswordName);
                        closeModal();
                        return;
                    }
                }
            }
            success=false;
            errorPassword = true;
        }catch (e){
            success = false;
            errorPassword = true;
        }
        sending = false;
    };
</script>
<main class="modalGeneral" transition:fly="{{ y: -200, duration: 500 }}">
    <div class="modal-header">
        {#if openAdmin}
            <section class="text-center">Set the password for open the door</section>
        {:else}
            <section class="text-center">Enter the password to open the door</section>
        {/if}
    </div>
    <div class="modal-content">
        <section>
            <input type="password" bind:value={password} name="password" id="roomPasswordPassword">
        </section>
    </div>
    <div class="modal-footer">
        {#if errorPassword}
            <section class="error-section">
               <p class="err">Invalid password</p>
            </section>
        {/if}
        <section class="action">
            {#if $userIsMapAdminStore}
                <button on:click|preventDefault={ _=>{openAdmin=!openAdmin;password='';success=false} }>
                    {#if openAdmin}
                        Back
                    {:else}
                        Change password
                    {/if}
                </button>
            {/if}
            <button on:click|preventDefault={_=>{!sending&&submit()}}>{sending?"Wait...":"Send"}</button>
            <button on:click|preventDefault={_=>{!sending&&closeModal()}}>{sending?"Wait...":"Close"}</button>
        </section>
    </div>
</main>