<script lang="typescript">
    import { fly } from "svelte/transition";
    import type { Game } from "../../Phaser/Game/Game";
    import { createChatGroup } from "../../Stores/ConsoleGlobalMessageManagerStore";
    import { createGroup } from "../../Stores/ChatStore";
    import MultiSelect from "./MultiSelect.svelte";
    import { localUserStore } from "../../Connexion/LocalUserStore";
    import { GameScene } from "../../Phaser/Game/GameScene";
    import { onMount } from "svelte";
    import Axios from "axios";
    import { PUSHER_URL } from "../../Enum/EnvironmentVariable";
    import { chatCurrentUserInfoStream } from "../../Stores/ChatStore";
    import type { GroupI, AddToGroupI } from "../../Connexion/ConnexionModels";

    export let game: Game;

    let values: [string] | [] = [];
    let instance = "";
    let promise = Promise.resolve([]);
    const group: GroupI = {
        name: "",
        identifiers: [],
        organization: "",
        uuid: "",
    };
    let inputElement = null;
    let isSaving = false;
    let gameScene: GameScene | null = null;

    const validators: any = {
        name: { validators: [required, minLength(3)], isValid: true },
        identifiers: { validators: [minLength(1)], isValid: true },
    };

    onMount(async () => {
        for (const scene of game.scene.getScenes(true)) {
            if (scene instanceof GameScene) {
                instance = scene.instance;
                gameScene = scene;
            }
        }

        const token = localUserStore.getAuthToken();
        promise = Axios.get(`${PUSHER_URL}/groups?organization=${instance}`, {
            headers: { "auth-token": `${token}` },
        }).then((data) => {
            return data.data;
        });
    });

    function required(value: any) {
        return !!value;
    }

    function minLength(size: number) {
        return (value: string | [unknown]) => value.length >= size;
    }

    function closeConsoleGlobalMessage() {
        createChatGroup.set(false);
    }

    function onKeyDown(e: KeyboardEvent) {
        if (e.key === "Escape") {
            closeConsoleGlobalMessage();
        }
    }

    function validateForm() {
        let fieldNames = Object.keys(validators);
        /* eslint-disable-next-line */
        const validations: any = {};
        fieldNames.reduce((acc, current) => {
            acc[current] = isFieldValid(current);
            return acc;
        }, validations);

        let keys = Object.keys(validations);
        let result = true;
        keys.forEach((key) => {
            validators[key].isValid = validations[key];
            result = result && validators[key].isValid;
        });

        return result;

        // applyValidationsToForm(validations);
    }

    function isFieldValid(field: string) {
        /* eslint-disable-next-line */
        let value: any = null;
        /* eslint-disable-next-line */
        let fieldValidations: any = null;
        switch (field) {
            case "name":
                value = group.name;
                fieldValidations = validators[field].validators;
                break;
            case "identifiers":
                value = group.identifiers;
                fieldValidations = validators[field].validators;
                break;
        }
        if (!fieldValidations) return true;
        let result = fieldValidations.reduce((isValid: boolean, nextValidation: Function) => {
            return isValid && nextValidation(value);
        }, true);
        return result;
    }

    async function confirm() {
        const token = localUserStore.getAuthToken();
        group.identifiers = values;
        group.organization = instance;

        if (validateForm() && token) {
            isSaving = true;
            const newGroup = await createGroup(token, group);
            isSaving = false;
            chatCurrentUserInfoStream.getStream().next({
                groups: [
                    {
                        name: group.name,
                        roomId: "",
                        identifiers: group.identifiers,
                        avatar: "",
                        uuid: newGroup._id,
                    },
                ],
            });
            const addToGroup: AddToGroupI = {
                group: {
                    name: group.name,
                    identifiers: group.identifiers,
                    uuid: newGroup._id,
                    organization: instance,
                },
            };
            gameScene?.addToGroup(addToGroup);
            closeConsoleGlobalMessage();
        }
    }
</script>

<svelte:window on:keydown={onKeyDown} />

<div class="console-global-message">
    <div class="main-console-global-message nes-container is-rounded" transition:fly={{ y: -1000, duration: 500 }}>
        <div class="title-console-global-message">
            <h2>Criar grupo</h2>
            <button type="button" class="nes-btn is-error" on:click|preventDefault={closeConsoleGlobalMessage}
                ><i class="nes-icon close is-small" /></button
            >
        </div>

        <div class="content-console-global-message">
            {#await promise}
                <div class="loading">
                    <p>Carregando...</p>
                </div>
            {:then users}
                <div class="form-input">
                    <div class="label">Nome do grupo</div>
                    <input
                        class:input-error={!validators.name.isValid}
                        type="text"
                        bind:value={group.name}
                        placeholder="Digite aqui..."
                        on:focus={() => {}}
                        on:input={() => {
                            validators.name.isValid = true;
                        }}
                        on:blur={() => {}}
                        bind:this={inputElement}
                    />
                </div>
                <div class="form-input">
                    <div class="label">Participantes</div>
                    <div class:input-error={!validators.identifiers.isValid}>
                        <MultiSelect id="lang" bind:value={values}>
                            <option selected disabled value="">Selecione...</option>
                            {#each users as { name, email } (email)}
                                <option value={email}>{name}</option>
                            {/each}
                        </MultiSelect>
                    </div>
                </div>
            {:catch error}
                <p style="color: red">{error.message}</p>
            {/await}
        </div>
        <div class="footer-console-global-message">
            <button disabled={isSaving} class="nes-btn is-primary" on:click|preventDefault={confirm}
                >{isSaving ? "Salvando..." : "Confirmar"}</button
            >
        </div>
    </div>
</div>

<style lang="scss">
    .input-error {
        border: 0.2rem solid red;
    }
    .label {
        font-family: Lato;
        font-size: 1.1rem;
        color: white;
        margin-right: 1rem;
        display: inline;
        margin-bottom: 0.3rem;
    }
    .form-input {
        margin-bottom: 1rem;
    }
    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }
    .nes-container {
        padding: 0 5px;
    }

    div.console-global-message {
        top: 20vh;
        width: 50vw;
        height: 50vh;
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
        padding: 0;

        pointer-events: auto;

        div.menu-console-global-message {
            flex: 1 1 auto;
            max-width: 180px;

            text-align: center;
            background-color: #333333;

            button {
                width: 136px;
                margin-bottom: 10px;
            }
        }

        div.main-console-global-message {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;

            background-color: #333333;

            div.title-console-global-message {
                flex: 0 0 auto;
                height: 50px;
                margin-bottom: 10px;

                text-align: center;
                color: whitesmoke;

                .nes-btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            div.content-console-global-message {
                flex: 1 1 auto;
                max-height: calc(100% - 120px);
            }

            div.footer-console-global-message {
                height: 50px;
                margin-top: 10px;
                text-align: center;

                label {
                    margin: 0;
                    position: absolute;
                    left: 0;
                    max-width: 30%;
                }
            }
        }
    }
</style>
