import type { Room } from "../Connexion/Room";

export enum GameConnexionTypes {
    anonymous = 1,
    organization,
    register,
    empty,
    unknown,
    jwt,
    createMap,
    organizationRequest,
    organizationList,
    invited,
    acceptRefuse,
    group,
}

//this class is responsible with analysing and editing the game's url
class UrlManager {
    public getGameConnexionType(): GameConnexionTypes {
        const url = window.location.pathname.toString();
        if (url === "/jwt") {
            return GameConnexionTypes.jwt;
        } else if (url.includes("_/")) {
            return GameConnexionTypes.anonymous;
        } else if (url.includes("@/")) {
            const afterUrl = url.split("@/").pop();
            if (afterUrl?.split("/").length == 1) {
                return GameConnexionTypes.organizationList;
            }
            if (afterUrl?.includes("accept/") || afterUrl?.includes("refuse/")) {
                return GameConnexionTypes.acceptRefuse;
            }
            return GameConnexionTypes.organization;
        } else if (url.includes("invite/")) {
            return GameConnexionTypes.invited;
        } else if (url.includes("organizationReq")) {
            return GameConnexionTypes.organizationRequest;
        } else if (url.includes("group/")) {
            return GameConnexionTypes.group;
        } else if (url.includes("register/")) {
            return GameConnexionTypes.register;
        } else if (url.includes("createMap")) {
            return GameConnexionTypes.createMap;
        } else if (url === "/") {
            return GameConnexionTypes.empty;
        } else {
            return GameConnexionTypes.unknown;
        }
    }

    public getOrganizationToken(): string | null {
        const match = /\/register\/(.+)/.exec(window.location.pathname.toString());
        return match ? match[1] : null;
    }

    public pushRoomIdToUrl(room: Room): void {
        if (window.location.pathname === room.id) return;
        const hash = window.location.hash;
        const search = room.search.toString();
        history.pushState({}, "WorkAdventure", room.id + (search ? "?" + search : "") + hash);
    }

    public getStartLayerNameFromUrl(): string | null {
        const hash = window.location.hash;
        return hash.length > 1 ? hash.substring(1) : null;
    }

    pushStartLayerNameToUrl(startLayerName: string): void {
        window.location.hash = startLayerName;
    }
}

export const urlManager = new UrlManager();
