<script lang="typescript">
    import { connectionManager } from "../../Connexion/ConnectionManager";
    import {GameConnexionTypes} from "../../Url/UrlManager";
    import {userIsOrgOwnerStore} from "../../Stores/GameStore";
    import {gameManager} from "../../Phaser/Game/GameManager";
    import {localUserStore} from "../../Connexion/LocalUserStore";
    import {MenuScene} from "../../Phaser/Menu/MenuScene";
    import {modal, modalListOwner} from "../../Stores/ModalStore";
    let showMenu = false;

    function userMenuClick(): void {
        showMenu = !showMenu;
    }
    function listOwnerModal() {
        modal.set(true);
        modalListOwner.set(true);
    }

    function onLoginClick() {
        console.log("login");
    }
    console.log(connectionManager.getConnexionType);
    let mapOrgQtd:Number;
    const getOrgMaps = async ()=>{
        try{
            const response = await gameManager.getOrgMaps();
            mapOrgQtd = response.length;
        }catch (e) {
            alert("Erro ao pegar as organizações");
        }
    }
    getOrgMaps();
</script>

<div>
    {#if connectionManager.getConnexionType !== GameConnexionTypes.anonymous}
        <div class="btn-user-menu">
            <div
                style="background-image: url('static/images/no_avatar.png'); background-size: cover;"
                class=""
                on:click={userMenuClick}
            />
        </div>
    {:else}
        <button
            on:click={() => {
                connectionManager.loadOpenIDScreen();
            }}
            style="pointer-events: all; position: absolute; display: block; top: 10px; right: 15px; width: 240px; height: 40px; text-align: center; align-content: center; align-items: center; justify-content: flex-end; justify-items: center; width: 100px; font-family: 'Roboto'; background-color: black; color: white; width: 100px;"
        >
            Login
        </button>
    {/if}

    {#if showMenu}
        <div id="userMenu">
            <div>
                <section>
                    <button id="shareButton">Compartilhar URL</button>
                </section>
                <section>
                    <button id="changeNameButton">Editar nome</button>
                </section>
                <section>
                    <button id="changeSkinButton">Editar avatar</button>
                </section>
                <section>
                    <button id="changeCompanionButton">Editar companheiro</button>
                </section>
                <section id="setOwnerSection">
                    <button on:click={_=>listOwnerModal()} id="setOwnerButton">Set Owner</button>
                </section>
                {#if $userIsOrgOwnerStore && mapOrgQtd>1}
                    <section>
                        <button on:click={_=>window.location.href = (`/group/${localUserStore.getOrganization()}`)} id="groupMaps">Juntar todos os mapas</button>
                    </section>
                    <section>
                        <button on:click={_=>window.location.href = (`/@/${localUserStore.getOrganization()}`)} id="listMaps">Listar os mapas da organização</button>
                    </section>
                {/if}
                <section>
                    <button on:click={ connectionManager.logout } id="menuUserExitButton">Sair</button>
                </section>
            </div>
        </div>
    {/if}
</div>

<style>
    #userMenu {
        right: 10px;
        z-index: 2;
        position: absolute;
    }
    #userMenu * {
        pointer-events: all;
    }
    #userMenu div {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 65px;
    }
    #userMenu button {
        background-color: black;
        color: white;
        border-radius: 7px;
        padding-bottom: 2px;
    }
    #userMenu section {
        margin: 10px;
    }

    @media only screen and (max-height: 700px) {
        #userMenu div {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            flex-wrap: wrap;
            margin-top: 0;
        }
        #userMenu section {
            margin: 2px;
        }
    }
</style>
