<script lang="typescript">
    import type {Game} from "../../Phaser/Game/Game";
    import {gameManager} from "../../Phaser/Game/GameManager";
    import {SetEmailUser, SetEmailUserScene} from "../../Phaser/CreateMap/SetEmailUser";
    import {validateEmail} from "../../Connexion/LocalUser";
    import logoImg from "../images/logo.png";
    import {DISPLAY_TERMS_OF_USE, MAX_USERNAME_LENGTH} from "../../Enum/EnvironmentVariable";

    export let game: Game;
    let login = gameManager.getEmailUser() !== "";
    let startValidating = false;
    const setEmailUser = game.scene.getScene(SetEmailUserScene) as SetEmailUser;
    let requesting = false;
    const values ={
        login: {
            email: '',
            password: '',
            errorRec:false,
            errorRecText:'',
            startValidating: false
        },
        register:{
            email: '',
            name: '',
            password: '',
            errorRec:false,
            errorRecText:'',
            startValidating: false
        }
    }

    async function Continue() {
        let finalEmail;
        let finalPassword;
        let finalName;
        let finalOrganization;
        if(!login) {
            values.register.startValidating = true;
            finalEmail = values.register.email.trim();
            finalPassword = values.register.password;
            finalName = values.register.name.trim();
            finalOrganization = /(?<=@)[^.]+(?=\.)/.exec(values.register.email.trim()).pop();
        }else{
            values.login.startValidating = true;
            finalEmail = values.login.email.trim();
            finalPassword = values.login.password;
            finalName = "login";
            finalOrganization = "login";
        }
        if (validateEmail(finalEmail) && !requesting) {
            requesting = true;
            if (finalEmail !== '' && finalPassword !== ''  && finalName !== '' && finalOrganization !== '') {
                const success = await setEmailUser.Continue(finalEmail, finalPassword,finalName,finalOrganization, login);
                if (!success) {
                    if (login) {
                        values.login.errorRec = true;
                        values.login.errorRecText = `Erro ao tentar logar!`;
                    }else{
                        values.register.errorRec = true;
                        values.register.errorRecText = `Erro ao tentar cadastrar!`;
                    }
                }
            }
            requesting = false;
        }
    }


</script>

<form class="selectMapSize">
    <section class="text-center">
        <img src={logoImg} alt="WorkAdventure logo" />
    </section>
    {#if login}
        <section class="text-center">
            <h2>FAÇA LOGIN</h2>
            <section>
                <input type="text" name="loginSceneName" class="nes-input is-dark" autofocus bind:value={values.login.email}
                       placeholder="Email"
                       class:is-error={(values.login.email.trim() === ''||!validateEmail(values.login.email.trim())) && startValidating}/>
                <section class="error-section">
                    {#if values.login.email.trim() === '' && startValidating }
                        <p class="err">O email esta vazio</p>
                    {:else if !validateEmail(values.login.email.trim()) && startValidating }
                        <p class="err">O email não é um email valido</p>
                    {/if}
                </section>

            </section>
            <section>
                <input type="password" name="loginScenePass" class="nes-input is-dark" autofocus bind:value={values.login.password}
                       placeholder="Password"
                       class:is-error={values.login.password.trim() === '' && startValidating}/>
                <div class="error-section">
                    {#if values.login.password.trim() === '' && startValidating }
                        <p class="err">A senha está vazia</p>
                    {/if}
                    {#if values.login.errorRec }
                        <p class="err">{values.login.errorRecText}</p>
                    {/if}
                </div>
            </section>
        </section>
        <section class="action">
            <span>
                ainda não tem conta?
                <a on:click|preventDefault={ _=>{login=false;values.login.startValidating=false} }>CADASTRE-SE</a>
            </span><br>

            <button type="submit" class="selectMapSizeFormSubmit nes-btn is-primary"
                    on:click|preventDefault={ Continue }>Continuar
            </button>
        </section>
    {:else }
        <section class="text-center">
            <h2>CADASTRE-SE</h2>
            <section>
                <input type="text" name="loginSceneName" class="nes-input is-dark" autofocus maxlength={MAX_USERNAME_LENGTH} bind:value={values.register.name}
                       placeholder="Name"
                       class:is-error={(values.register.name.trim() === '') && startValidating}/>
                <div class="error-section">
                    {#if values.register.name.trim() === '' && startValidating }
                        <p class="err">O nome está vazio</p>
                    {/if}
                </div>
            </section>
            <section>
                <input type="text" name="loginSceneName" class="nes-input is-dark" autofocus bind:value={values.register.email}
                       placeholder="Email"
                       class:is-error={(values.register.email.trim() === ''||!validateEmail(values.register.email.trim())) && startValidating}/>
                <div class="error-section">
                    {#if values.register.email.trim() === '' && startValidating }
                        <p class="err">O email está vazio</p>
                    {:else if !validateEmail(values.register.email.trim()) && startValidating }
                        <p class="err">O email não é um email valido</p>
                    {/if}
                </div>
            </section>
            <section>
                <input type="password" name="loginScenePass" class="nes-input is-dark" autofocus bind:value={values.register.password}
                       placeholder="Password"
                       class:is-error={values.register.password === '' && startValidating}/>
                <div class="error-section">
                    {#if values.register.password === '' && startValidating }
                        <p class="err">A senha está vazia</p>
                    {/if}
                    {#if values.register.errorRec }
                        <p class="err">{values.register.errorRecText}</p>
                    {/if}
                </div>
            </section>
        </section>

        {#if DISPLAY_TERMS_OF_USE}
            <section class="terms-and-conditions">
                <p>Ao continuar, você concorda com nosso <a href="https://workadventu.re/terms-of-use" target="_blank">termos de uso</a>, <a href="https://workadventu.re/privacy-policy" target="_blank">política de privacidade</a> e <a href="https://workadventu.re/cookie-policy" target="_blank">política de cookies</a>.</p>
            </section>
        {/if}
        <section class="action">
            <span>
                Já tem conta?
                <a on:click|preventDefault={ _=>{login=true;values.register.startValidating=false} }>faça seu login</a>
            </span><br>
            <button type="submit" class="selectMapSizeFormSubmit nes-btn is-primary"
                    on:click|preventDefault={ Continue }>Continuar
            </button>
        </section>
    {/if}

</form>

<style lang="scss">
    form.selectMapSize {
        font-family: "Press Start 2P",serif;
        pointer-events: auto;
        margin: 20px auto 0;
        width: 90%;
        color: #ebeeee;

        display: flex;
        flex-flow: column wrap;
        align-items: center;

        button {
            font-family: "Press Start 2P",serif;
            &.active {
                background: #5e6063;
                color: white;
            }
        }
        input {
            text-align: center;
            font-family: "Press Start 2P",serif;
            max-width: 400px;
        }

        .terms-and-conditions {
            max-width: 400px;
        }

        p.err {
            color: #ce372b;
            text-align: center;
        }
        section {
            margin: 10px;

            &.error-section {
                min-height: 2rem;
                margin: 0;

                p {
                    margin: 0;
                }
            }

            &.action {
                text-align: center;
                margin-top: 20px;
            }

            h2 {
                font-family: "Press Start 2P";
                margin: 1px;
            }

            &.text-center {
                text-align: center;
            }

            a {
                text-decoration: underline;
                color: #ebeeee;
            }

            a:hover {
                font-weight: 700;
            }

            p {
                text-align: left;
                margin: 10px 10px;
            }

            img {
                width: 100%;
                margin: 20px 0;
            }
        }
    }
</style>
