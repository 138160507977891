import {
    chatStore,
    chatMessagesStream,
    chatCurrentUserInfoStream,
    getPreviousMessagesByUuid,
    uploadChatFile,
    createFileDownloadLink,
} from "../../Stores/ChatStore";
import type { MessageUserDirect, MessageJoinChat } from "../../Connexion/ConnexionModels";
import { GameScene } from "../../Phaser/Game/GameScene";

import React, { useState, useEffect } from "react";
const { Channel } = require("@julianograms/react-chat-elements");
import { localUserStore } from "../../Connexion/LocalUserStore";
import { createChatGroup, createDirectMessageChannel } from "../../Stores/ConsoleGlobalMessageManagerStore";
import { PUSHER_URL } from "../../Enum/EnvironmentVariable";

/* eslint-disable-next-line */
const Wrapper = (props: any) => {
    const game = props.game;
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [messages, setMessages] = useState<Array<any>>([]); /* eslint-disable-line */
    const [users, setUsers] = useState<Array<any>>([]); /* eslint-disable-line */
    const [groups, setGroups] = useState<Array<any>>([]); /* eslint-disable-line */
    const [selectedChannel, setSelectedChannel] = useState<any>(null); /* eslint-disable-line */
    const [newMessage, setNewMessage] = useState<any>(null); /* eslint-disable-line */
    const [newUser, setNewUser] = useState<any>(null); /* eslint-disable-line */

    function onChannelChangeChat() {
        chatStore.getStream().subscribe((joinedUser: MessageJoinChat | string) => {
            setNewUser(joinedUser);
        });
    }

    function onMessageReceived() {
        chatMessagesStream.getStream().subscribe((event) => {
            setNewMessage(event);
        });
    }

    function onUserInfoChanged() {
        chatCurrentUserInfoStream.getStream().subscribe((a) => {
            setGroups((groups) => {
                return groups.concat(
                    /* eslint-disable-next-line */
                    a.groups.map((group: any) => {
                        return {
                            ...group,
                            messages: [],
                        };
                    })
                );
            });
        });
    }

    useEffect(() => {
        onChannelChangeChat();
        onMessageReceived();
        onUserInfoChanged();
    }, []);

    useEffect(() => {
        if (newMessage) {
            console.log(newMessage, selectedChannel);
            const localUser = localUserStore.getLocalUser();
            for (const scene of game.scene.getScenes(true)) {
                if (scene instanceof GameScene) {
                    scene.sound.play("audio-chat-message-notification", {
                        volume: 1,
                    });
                }
            }
            let messages = [];
            const onlineChatUser = users.find(
                /* eslint-disable-next-line */
                (user: any) => user.uuid === newMessage.sender && newMessage.to === localUser?.uuid
            );
            const isMessageFromGroup = !onlineChatUser;
            const selectedChannelUuid = selectedChannel && selectedChannel.uuid;

            const isFromSameUser = !isMessageFromGroup && selectedChannelUuid === newMessage.sender;
            const isFromSameGroup = isMessageFromGroup && selectedChannelUuid === newMessage.to;
            const isFromSameChannel = isFromSameGroup || isFromSameUser;
            const shouldNotify = !isFromSameChannel;

            if (isFromSameChannel) {
                messages = selectedChannel.messages;
                setSelectedChannel({ ...selectedChannel, messages: messages });
            }
            let message = null;
            if (newMessage.type === "file") {
                message = {
                    type: "file",
                    data: {
                        size: newMessage.file.size,
                        uri: newMessage.file.uuid,
                        status: {
                            click: false,
                            loading: 0,
                            isSending: false,
                        },
                    },
                    text: newMessage.file.name,
                    avatar: "static/images/no_avatar.png",
                    time: new Date(newMessage.date),
                    title: newMessage.name,
                    author: "them",
                };
            } else {
                message = {
                    time: new Date(newMessage.date),
                    type: "text",
                    title: newMessage.name,
                    author: "them",
                    text: newMessage.text,
                    avatar: "static/images/no_avatar.png",
                };
            }
            messages.push(message);

            if (onlineChatUser) {
                onlineChatUser.messages = messages;

                if (shouldNotify) {
                    if (!isNaN(onlineChatUser.unread)) onlineChatUser.unread++;
                    else onlineChatUser.unread = 1;
                }

                setUsers([...users]);
            } else {
                /* eslint-disable-next-line */
                const group = groups.find((group: any) => group.uuid === newMessage.to);
                if (group) {
                    group.messages = messages;
                    if (shouldNotify) {
                        if (!isNaN(group.unread)) group.unread++;
                        else group.unread = 1;
                    }

                    setGroups([...groups]);
                }
            }
        }
    }, [newMessage]);

    useEffect(() => {
        if (newUser === null) return;
        if (typeof newUser === "string") {
            setUsers(users.filter((user: any) => user.uuid !== newUser)); /* eslint-disable-line */
            if (selectedChannel?.uuid === newUser) {
                setMessages([]);
                setIsOpen(false);
            }
        } else {
            /* eslint-disable-next-line */
            if (users.every((user: any) => user.uuid !== newUser.uuid)) {
                /* eslint-disable-line */
                users.push({ ...newUser, avatar: "static/images/no_avatar.png", unread: 0, messages: [] });
                setUsers([...users]);
            }
        }
    }, [newUser]);

    /* eslint-disable-next-line */
    function onSendMessage(message: any) {
        /* eslint-disable-next-line */
        setSelectedChannel((sc: any) => {
            if (sc && message) {
                sc.messages.push({
                    ...message,
                    // position: message.author === "me" ? "right" : "left",
                    type: "text",
                    text: message.data.text,
                    // deletable: false,
                    date: message.time,
                    title: "Eu",
                });

                const userMessage: MessageUserDirect = {
                    userId: sc?.userId,
                    text: message.data.text,
                    uuid: sc?.uuid,
                    date: new Date(),
                    identifiers: sc.identifiers,
                    type: "text",
                    file: null,
                };

                for (const scene of game.scene.getScenes(true)) {
                    if (scene instanceof GameScene) {
                        scene.sendDirectMessage(userMessage);
                    }
                }
                const listUser = sc.identifiers
                    ? /* eslint-disable-next-line */
                      groups.find((group: any) => group.uuid === sc.uuid)
                    : /* eslint-disable-next-line */
                      users.find((user: any) => user.uuid === sc.uuid);
                listUser.messages = sc.messages;
                setUsers([...users]);
            }
            return { ...sc };
        });
    }
    /* eslint-disable-next-line */
    function formatBytes(a: any, b = 2) {
        if (0 === a) return "0 Bytes";
        const c = 0 > b ? 0 : b,
            d = Math.floor(Math.log(a) / Math.log(1024));
        return (
            parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
            " " +
            ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
        );
    }

    async function onFileUpload(fileList: FileList) {
        const file = fileList[0];
        if (file) {
            const formData = new FormData();
            formData.append("file", file);

            /* eslint-disable-next-line */
            if (selectedChannel) {
                const fileSize = formatBytes(file.size);
                const message = {
                    type: "file",
                    text: file.name,
                    author: "me",
                    data: {
                        size: fileSize,
                        uri: "",
                        status: {
                            click: true,
                            loading: 0,
                            isSending: true,
                        },
                    },
                    date: new Date(),
                    title: "Eu",
                };
                selectedChannel.messages.push(message);
                setSelectedChannel({ ...selectedChannel });

                const result = await uploadChatFile(formData, selectedChannel.uuid);
                message.data.uri = result.file;
                message.data.status.isSending = false;
                message.data.status.click = false;

                const userMessage: MessageUserDirect = {
                    userId: selectedChannel?.userId,
                    text: null,
                    file: {
                        name: result.name,
                        uuid: result.file,
                        size: fileSize,
                    },
                    uuid: selectedChannel?.uuid,
                    date: message.date,
                    identifiers: selectedChannel.identifiers,
                    type: "file",
                };

                for (const scene of game.scene.getScenes(true)) {
                    if (scene instanceof GameScene) {
                        scene.sendDirectMessage(userMessage);
                    }
                }
                const listUser = selectedChannel.identifiers
                    ? /* eslint-disable-next-line */
                      groups.find((group: any) => group.uuid === selectedChannel.uuid)
                    : /* eslint-disable-next-line */
                      users.find((user: any) => user.uuid === selectedChannel.uuid);
                listUser.messages = selectedChannel.messages;
                setUsers([...users]);
            }
            setSelectedChannel({ ...selectedChannel });
        }
    }

    /* eslint-disable-next-line */
    async function onChannelChange(item: any, type: string) {
        const token = localUserStore.getAuthToken();
        const localUser = localUserStore.getLocalUser();
        if (token) {
            const previousMessages = await getPreviousMessagesByUuid(token, item.uuid, type);
            /* eslint-disable-next-line */
            item.messages = previousMessages.map((pm: any) => {
                const isMe = pm.senderUuid === localUser?.uuid;
                if (pm.type === "file") {
                    return {
                        time: new Date(pm.date),
                        type: "file",
                        title: isMe ? "Eu" : pm.name ? pm.name : item.name,
                        author: isMe ? "me" : "them",
                        data: {
                            size: pm.file.size,
                            uri: pm.file.uuid,
                            status: {
                                click: false,
                                loading: 0,
                                isSending: false,
                            },
                        },
                        text: pm.file.name,
                        avatar: "static/images/no_avatar.png",
                    };
                } else {
                    return {
                        time: new Date(pm.date),
                        type: "text",
                        title: isMe ? "Eu" : pm.name ? pm.name : item.name,
                        author: isMe ? "me" : "them",
                        text: pm.text,
                        avatar: "static/images/no_avatar.png",
                    };
                }
            });
        }

        setSelectedChannel(() => {
            item.unread = 0;
            /* eslint-disable-next-line */
            if (type === "group") {
                setGroups((groups) => {
                    const user = groups.find(
                        /* eslint-disable-next-line */
                        (group: any) => group.uuid === item.uuid
                    );
                    user.unread = 0;
                    return [...groups];
                });
            } else {
                setUsers((users) => {
                    const user = users.find(
                        /* eslint-disable-next-line */
                        (user: any) => user.userId === item.userId
                    );
                    user.unread = 0;
                    return [...users];
                });
            }

            return item;
        });
        setIsOpen(() => true);
    }

    return (
        <Channel
            /* eslint-disable-next-line */
            onDownload={async (message: any) => {
                const token = localUserStore.getAuthToken();
                if (token) {
                    const fileToken = await createFileDownloadLink(token, {
                        uuid: message.data.uri,
                        name: message.text,
                        type: message.type,
                    });
                    /* eslint-disable-next-line */
                    let link: any = document.createElement("a");
                    link.download = message.text;

                    link.href = `${PUSHER_URL}/download_chat_file?token=${fileToken}&name=${message.text}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }}
            onDirectMessageClick={() => {
                createDirectMessageChannel.set(true);
            }}
            users={users}
            messages={selectedChannel?.messages || []}
            show={show}
            onFileUpload={onFileUpload}
            onCreateGroupClick={() => createChatGroup.set(true)}
            toggleFloatButton={() => {
                for (const scene of game.scene.getScenes(true)) {
                    if (scene instanceof GameScene) {
                        if (!show) {
                            scene.userInputManager.disableControls();
                        } else {
                            setSelectedChannel(null);
                            setIsOpen(false);
                            scene.userInputManager.restoreControls();
                        }
                    }
                }
                setShow(!show);
            }}
            alertCount={
                users.reduce((acc, user) => (user.unread || 0) + acc, 0) +
                groups.reduce((acc, group) => (group.unread || 0) + acc, 0)
            }
            onClose={() => {
                setIsOpen(false);
                setSelectedChannel(null);
            }}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            groups={groups}
            selectedChannel={selectedChannel}
            onSendMessage={onSendMessage}
            onChannelChange={onChannelChange}
        />
    );
};

export default Wrapper;
