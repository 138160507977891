<script lang="typescript">
    import { fly } from 'svelte/transition';
    import {gameManager} from "../../../Phaser/Game/GameManager";
    let openAdmin = false;
    let sending = false;
    let success = false;
    let errorPassword = false;
    let organizations:[String] = [];
    let organizationSelected:String = "";
    let users:[{email:String;}] = [];
    let userSelected:String = "";
    export let closeModal: ()=>{};
    const getOrgs = async ()=>{
        try{
            const response = await gameManager.getOrganizations();
            organizations = response.organizations
        }catch (e) {
            alert("Erro ao pegar as organizações");
        }
    }
    getOrgs();
    const getUsers = async ()=>{
        if (organizationSelected === "") {
            userSelected = "";
            users = [];
            return;
        }
        try{
            const response = await gameManager.getUsers(organizationSelected);
            users = Object.values(response);
        }catch (e) {
            alert("Erro ao pegar os usuarios");
        }
    }
    const submit= async ()=>{
        sending = true;
        errorPassword = false;
        if (organizationSelected ==="" || userSelected === ""){
            errorPassword = true;
            sending = false;
            return;
        }
        try {
            const response = await gameManager.setOwner(organizationSelected, userSelected);
            if (response.success){
                success = true;
                sending = false;
                closeModal();
                alert(`Usuario ${userSelected} setado com sucesso para a organização ${organizationSelected}`);
                return;
            }
            success=false;
            errorPassword = true;
        }catch (e){
            success = false;
            errorPassword = true;
        }
        sending = false;
    };
</script>
<main class="modalGeneral" transition:fly="{{ y: -200, duration: 500 }}">
    <div class="modal-header">
        <section class="text-center">SetOwner</section>
    </div>
    <div class="modal-content">
        <section>
            Selecione uma organização<br>
            <select bind:value={organizationSelected} on:change={_=>getUsers()} name="organization" id="organization">
                <option value="">Selecione uma organização</option>
                {#each organizations as organization}
                    <option value="{organization}">{organization}</option>
                {/each}
            </select><br>
            Selecione um usuario para ser o owner<br>
            {#if organizationSelected == ""}
                <select disabled name="user" id="userdisabled">
                    <option value="">Selecione um usuario para ser o owner</option>
                </select>
            {:else}
                <select bind:value={userSelected} name="user" id="user">
                    <option value="">Selecione um usuario para ser o owner</option>
                    {#each users as user}
                        <option value="{user.email}">{user.email}</option>
                    {/each}
                </select>
            {/if}

        </section>
    </div>
    <div class="modal-footer">
        {#if errorPassword}
            <section class="error-section">
               <p class="err">Selecione uma organização e um usuario</p>
            </section>
        {/if}
        <section class="action">
            <button on:click|preventDefault={_=>{!sending&&submit()}}>{sending?"Wait...":"Send"}</button>
            <button on:click|preventDefault={_=>{!sending&&closeModal()}}>{sending?"Wait...":"Close"}</button>
        </section>
    </div>
</main>