<script lang="typescript">
    import type {Game} from "../../Phaser/Game/Game";
    import {SelectMapName, SelectMapNameScene} from "../../Phaser/CreateMap/SelectMapName";
    import {gameManager} from "../../Phaser/Game/GameManager";
    import {GameConnexionTypes, urlManager} from "../../Url/UrlManager";

    export let game: Game;
    let name = gameManager.getSelectedMapName() || '';
    let startValidating = false;
    const selectMapName = game.scene.getScene(SelectMapNameScene) as SelectMapName;


    function Continue() {
        startValidating = true;

        let finalName = name.trim();
        if (finalName !== '') {
            if (urlManager.getGameConnexionType()===GameConnexionTypes.group) {
                if (confirm("Tem certeza que deseja continuar? (Todos os mapas serão removidos)")){
                    selectMapName.Group(finalName);
                }
                return;
            }
            selectMapName.Continue(finalName);
        }
    }



</script>

<form class="selectMapSize">
    <section class="text-center">
        <h2>Digite o nome do mapa:</h2>
        {#if urlManager.getGameConnexionType()===GameConnexionTypes.group}
            <span style="color: red">(Ao completar o cadastro do mapa todos os outros mapas serão removidos)</span><br>
        {/if}
        <input type="text" name="loginSceneName" class="nes-input is-dark" autofocus  bind:value={name} on:keypress={() => {startValidating = true}} class:is-error={name.trim() === '' && startValidating} />
    </section>
    <section class="action">
        <button type="submit" class="selectMapSizeFormSubmit nes-btn is-primary" on:click|preventDefault={ Continue }>Continuar</button>
    </section>
</form>

<style lang="scss">
  form.selectMapSize {
    font-family: "Press Start 2P";
    pointer-events: auto;
    color: #ebeeee;

    section {
      margin: 10px;

      &.action {
        text-align: center;
      }

      h2 {
        font-family: "Press Start 2P";
        margin: 1px;
      }

      &.text-center {
        text-align: center;
      }
        .nes-input{
            max-width: 300px;
            width: 80%;
        }
    }
    .selectMapSizeImage{
      width: 20%;
    }
    button {
      font-family: "Press Start 2P";
      &.active{
        background: #212529;
        color: white;
      }
    }
  }
</style>
