import { gameManager } from "../Game/GameManager";
import { selectedMapName } from "../../Stores/CreateMapStore";
import { LoginSceneName } from "../Login/LoginScene";
import { Scene } from "phaser";
import { EntrySceneName } from "../Login/EntryScene";
import { urlManager } from "../../Url/UrlManager";
import { SetEmailUserScene } from "./SetEmailUser";

export const SelectMapNameScene = "SelectMapNameScene";
export class SelectMapName extends Scene {
    constructor() {
        super({
            key: SelectMapNameScene,
        });
    }

    preload() {}

    create() {
        selectedMapName.set(true);
    }

    public async Continue(finalName: string) {
        try {
            gameManager.setSelectedMapName(finalName);
            const response = await gameManager.CreateMap();
            if (response) {
                window.location.href = `/@/${response.organization}/${response.name}`;
                selectedMapName.set(false);
            }
            this.scene.stop(SelectMapNameScene);
            gameManager.tryResumingGame(this, SetEmailUserScene);
            this.scene.remove(SelectMapNameScene);
            return;
        } catch (e) {
            console.log(e);
            alert("erro ao criar o mapa");
            return;
        }
    }
    public async Group(finalName: string) {
        try {
            gameManager.setSelectedMapName(finalName);
            const response = await gameManager.GroupMaps();
            if (response) {
                window.location.href = `/@/${response.organization}/${response.name}`;
                selectedMapName.set(false);
            }
            this.scene.stop(SelectMapNameScene);
            gameManager.tryResumingGame(this, SetEmailUserScene);
            this.scene.remove(SelectMapNameScene);
            return;
        } catch (e) {
            console.log(e);
            alert("erro ao criar o mapa");
            return;
        }
    }

    update(time: number, delta: number): void {}
    public onResize(): void {}
}
