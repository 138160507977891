import { Subject } from "rxjs";

interface StatusEvent {
    userId: number;
    status: string;
}

class StatusEventStream {
    private _stream: Subject<StatusEvent> = new Subject();
    public stream = this._stream.asObservable();

    fire(userId: number, status: string) {
        this._stream.next({ userId, status: status });
    }
}

export const statusEventStream = new StatusEventStream();
