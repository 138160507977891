<script lang="typescript">
    import {enableCameraSceneVisibilityStore} from "../Stores/MediaStore";
    import CameraControls from "./CameraControls.svelte";
    import MenuUser from "./Chat/MenuUser.svelte";
    import MyCamera from "./MyCamera.svelte";
    import SelectCompanionScene from "./SelectCompanion/SelectCompanionScene.svelte";
    import {selectCompanionSceneVisibleStore} from "../Stores/SelectCompanionStore";
    import {selectCharacterSceneVisibleStore} from "../Stores/SelectCharacterStore";
    import SelectCharacterScene from "./selectCharacter/SelectCharacterScene.svelte";
    import {customCharacterSceneVisibleStore} from "../Stores/CustomCharacterStore";
    import {errorStore} from "../Stores/ErrorStore";
    import CustomCharacterScene from "./CustomCharacterScene/CustomCharacterScene.svelte";
    import LoginScene from "./Login/LoginScene.svelte";
    // import Chat from "./Chat/Chat.svelte";
    import {loginSceneVisibleStore} from "../Stores/LoginSceneStore";
    import EnableCameraScene from "./EnableCamera/EnableCameraScene.svelte";
    import VisitCard from "./VisitCard/VisitCard.svelte";
    import {requestVisitCardsStore} from "../Stores/GameStore";

    import type { Game } from "../Phaser/Game/Game";
    import { helpCameraSettingsVisibleStore } from "../Stores/HelpCameraSettingsStore";
    import HelpCameraSettingsPopup from "./HelpCameraSettings/HelpCameraSettingsPopup.svelte";
    import AudioPlaying from "./UI/AudioPlaying.svelte";
    import { soundPlayingStore } from "../Stores/SoundPlayingStore";
    import ErrorDialog from "./UI/ErrorDialog.svelte";
    import VideoOverlay from "./Video/VideoOverlay.svelte";
    import { gameOverlayVisibilityStore } from "../Stores/GameOverlayStoreVisibility";
    import {
        consoleGlobalMessageManagerVisibleStore,
        createChatGroup,
        createDirectMessageChannel,
    } from "../Stores/ConsoleGlobalMessageManagerStore";
    import ConsoleGlobalMessageManager from "./ConsoleGlobalMessageManager/ConsoleGlobalMessageManager.svelte";
    import CreateChatGroup from "./Chat/CreateChatGroup.svelte";
    import DirectMessage from "./Chat/DirectMessage.svelte";
    import AdminMessage from "./TypeMessage/BanMessage.svelte";
    import TextMessage from "./TypeMessage/TextMessage.svelte";
    import { banMessageVisibleStore } from "../Stores/TypeMessageStore/BanMessageStore";
    import { textMessageVisibleStore } from "../Stores/TypeMessageStore/TextMessageStore";
    import { warningContainerStore } from "../Stores/MenuStore";
    import WarningContainer from "./WarningContainer/WarningContainer.svelte";
    import { layoutManagerVisibilityStore } from "../Stores/LayoutManagerStore";
    import LayoutManager from "./LayoutManager/LayoutManager.svelte";
    import {listMaps, selectedMapName, selectedMapType, setEmailUser} from "../Stores/CreateMapStore";
    import SelectMapType from "./CreateMap/SelectMapType.svelte";
    import SelectMapName from "./CreateMap/SelectMapName.svelte";
    import SetEmailUser from "./CreateMap/SetEmailUser.svelte";
    import ModalGeneral from "./UI/modal/ModalGeneral.svelte";
    import {modal} from "../Stores/ModalStore";
    import ListMaps from "./ListMaps/ListMaps.svelte";
    export let game: Game;
</script>

{#if $modal}
    <style>
        .main_pointer{
            pointer-events: none;
        }
        #root{
            display: none;
        }
    </style>
{/if}
<div class="main_pointer">
    {#if $selectedMapType}
        <div class="scrollable">
            <SelectMapType {game}/>
        </div>
    {/if}
    {#if $selectedMapName}
        <div class="scrollable">
            <SelectMapName {game}/>
        </div>
    {/if}
    {#if $setEmailUser}
        <div class="scrollable">
            <SetEmailUser {game}/>
        </div>
    {/if}
    {#if $listMaps}
        <div class="scrollable">
            <ListMaps {game}/>
        </div>
    {/if}
    {#if $loginSceneVisibleStore}
        <div class="scrollable">
            <LoginScene {game}/>
        </div>
    {/if}
    {#if $selectCharacterSceneVisibleStore}
        <div>
            <SelectCharacterScene {game}/>
        </div>
    {/if}
    {#if $customCharacterSceneVisibleStore}
        <div>
            <CustomCharacterScene {game}/>
        </div>
    {/if}
    {#if $selectCompanionSceneVisibleStore}
        <div>
            <SelectCompanionScene {game}/>
        </div>
    {/if}
    {#if $enableCameraSceneVisibilityStore}
        <div class="scrollable">
            <EnableCameraScene {game}/>
        </div>
    {/if}
    {#if $banMessageVisibleStore}
        <div>
            <AdminMessage/>
        </div>
    {/if}
    {#if $textMessageVisibleStore}
        <div>
            <TextMessage/>
        </div>
    {/if}
    {#if $soundPlayingStore}
        <div>
            <AudioPlaying url={$soundPlayingStore}/>
        </div>
    {/if}
    {#if $layoutManagerVisibilityStore}
        <div>
            <LayoutManager/>
        </div>
    {/if}
    {#if $gameOverlayVisibilityStore}
        <div>
            <VideoOverlay/>
            <MyCamera/>
            <CameraControls/>
        </div>
    {/if}
    {#if $gameOverlayVisibilityStore}
        <MenuUser/>
    {/if}
    {#if $consoleGlobalMessageManagerVisibleStore}
        <div>
            <ConsoleGlobalMessageManager {game}/>
        </div>
    {/if}
    {#if $createChatGroup}
        <div>
            <CreateChatGroup {game}/>
        </div>
    {/if}
    {#if $createDirectMessageChannel}
        <div>
            <DirectMessage {game}/>
        </div>
    {/if}
    {#if $helpCameraSettingsVisibleStore}
        <div>
            <HelpCameraSettingsPopup/>
        </div>
    {/if}
    {#if $requestVisitCardsStore}
        <VisitCard visitCardUrl={$requestVisitCardsStore}/>
    {/if}
    {#if $errorStore.length > 0}
        <div>
            <ErrorDialog/>
        </div>
    {/if}
    <!-- {#if $chatVisibilityStore}
        <Chat />
    {/if} -->
    {#if $warningContainerStore}
        <WarningContainer/>
    {/if}
    {#if $modal}
        <ModalGeneral />
    {/if}
</div>
